<template>
  <div>
    <div class="header">
      <van-nav-bar :title="$t('hq.title')"> </van-nav-bar>
    </div>
    <div class="maincontent">
<!--      <van-tabs color="#004ea3" @click="change" v-model="activeindex">-->
        <!-- 市场 -->
<!--        <van-tab :title="$t('hq.market')">-->
          <!-- <van-tabs color="#004ea3"> -->
            <!-- <van-tab :title="$t('trade.title3')"> -->
              <van-row class="currency-title">
                <van-col span="9"
                  ><span>{{ $t('hq.hqname') }}</span></van-col
                >
                <van-col span="6"
                  ><span>{{ $t('hq.price') }}</span></van-col
                >
                <van-col span="9"
                  ><span>{{ $t('hq.updown') }}</span></van-col
                >
              </van-row>
              <van-row
                class="currency-Increase"
                v-for="item in productlist"
                :key="item.id"
                v-if="item.product_type_id==2"
              >
                <van-col span="9" style='align-items: center;display: flex;margin-left: 0.7rem'>
                  <van-image
                    width="2.5rem"
                    height="2.5rem"
                    :src="item.imageurl"
                  />
                  <div style='font-size: 0.8rem;margin-left: 0.5rem;'>{{ item.product_title }}</div>
                </van-col>
                <van-col span="6"
                  ><span style="color:black"
                    ><b>{{ item.current }}</b></span
                  ></van-col
                >
                <van-col span="9"
                  ><van-button
                    class="currency-btn"
                    :style="{
                      backgroundColor: item.change > 0 ? '#85B480' : '#B34F59'
                    }"
                    @click="totrade(item.pid)"
                  >
                    {{ item.change }}%
                  </van-button></van-col
                >
              </van-row>
<!--             </van-tab>-->
						<!-- <van-tab title="USDT">
						  <van-row class="currency-title">
						    <van-col span="8"
						      ><span>{{ $t('hq.hqname') }}</span></van-col
						    >
						    <van-col span="8"
						      ><span>{{ $t('hq.price') }}</span></van-col
						    >
						    <van-col span="8"
						      ><span>{{ $t('hq.updown') }}</span></van-col
						    >
						  </van-row>
						  <van-row
						    class="currency-Increase"
						    v-for="item in productlist"
						    :key="item.id"
						    v-if="item.product_type_id==1"
						  >
						    <van-col span="8"
						      ><span>{{ item.product_title }}</span></van-col
						    >
						    <van-col span="8"
						      ><span style="color:black"
						        ><b>{{ item.current }}</b></span
						      ></van-col
						    >
						    <van-col span="8"
						      ><van-button
						        class="currency-btn"
						        :style="{
						          backgroundColor: item.change > 0 ? '#85B480' : '#B34F59'
						        }"
						        @click="totrade(item.pid)"
						      >
						        {{ item.change }}%
						      </van-button></van-col
						    >
						  </van-row>
						</van-tab> -->
          <!-- </van-tabs> -->
<!--        </van-tab>-->
<!--        &lt;!&ndash; 自选 &ndash;&gt;-->
<!--        <van-tab :title="$t('hq.zixuan')">-->
<!--          <van-row class="currency-title">-->
<!--            <van-col span="8"-->
<!--              ><span>{{ $t('hq.hqname') }}</span></van-col-->
<!--            >-->
<!--            <van-col span="8"-->
<!--              ><span>{{ $t('hq.price') }}</span></van-col-->
<!--            >-->
<!--            <van-col span="8"-->
<!--              ><span>{{ $t('hq.updown') }}</span></van-col-->
<!--            >-->
<!--          </van-row>-->
<!--          <van-row-->
<!--            class="currency-Increase"-->
<!--            v-for="item in zxlist"-->
<!--            :key="item.id"-->
<!--          >-->
<!--            <van-col span="9" style='align-items: center;display: flex;margin-left: 0.7rem'>-->
<!--              <van-image-->
<!--                width="2.5rem"-->
<!--                height="2.5rem"-->
<!--                :src="require('../../assets/img/login.png')"-->
<!--              />-->
<!--              <div style='font-size: 0.8rem;margin-left: 0.5rem;'>{{ item.product_title }}</div>-->
<!--            </van-col>-->
<!--            <van-col span="6"-->
<!--            ><span style="color:black"-->
<!--            ><b>{{ item.current }}</b></span-->
<!--            ></van-col-->
<!--            >-->
<!--            <van-col span="9"-->
<!--            ><van-button-->
<!--              class="currency-btn"-->
<!--              :style="{-->
<!--                      backgroundColor: item.change > 0 ? '#85B480' : '#B34F59'-->
<!--                    }"-->
<!--              @click="totrade(item.pid)"-->
<!--            >-->
<!--              {{ item.change }}%-->
<!--            </van-button></van-col-->
<!--            >-->
<!--          </van-row>-->
<!--          <van-row class="click-add" @click="add">-->
<!--            <van-icon name="plus" style="font-size: 2rem;" />-->
<!--            <van-row>-->
<!--              <span style="color: rgb(198, 197, 197);">{{-->
<!--                $t('hq.addbtn')-->
<!--              }}</span>-->
<!--            </van-row>-->
<!--          </van-row>-->
<!--        </van-tab>-->
<!--      </van-tabs>-->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      newslist: [],
      productlist: [],
      zxlist: [],
      activeindex: 0,
      wk: null
    }
  },
  created() {
    this.getproduct()
    this.initwk()
  },
  methods: {
    async getnews() {
      const { data } = await this.$http.get('/home/news/newslist')
      if (data) {
        if (data.code === 200) {
          this.newslist = data.data
        }
      }
    },
    async getproduct() {
      const { data } = await this.$http.get('/home/news/productlist')
      if (data) {
        if (data.code === 200) {
          this.productlist = data.data
        }
      }
    },
    async getzxlist() {
      const { data } = await this.$http.get('/home/news/zxlist')
      if (data) {
        if (data.code === 200) {
          this.zxlist = data.data
        }
      }
    },
    // 新闻详情
    detail(id) {
      this.$router.push({ path: '/news/detail/' + id })
    },
    // 选项卡事件
    change(id) {
      if (id === 0) {
        this.getnews()
      }
      if (id === 1) {
        this.getproduct()
      }
      if (id === 2) {
        this.getzxlist()
      }
    },
    // 添加自选
    add() {
      this.$router.push('/trade')
    },
    // 去交易页
    totrade(id) {
      this.$router.push('/trade_' + id)
    },
    initwk() {
      this.wk = new WebSocket(this.wsurl + '/wssss:8081')
      this.wk.onmessage = this.wkonmessage
    },
    wkonmessage(e) {
      this.productlist = JSON.parse(e.data)
      this.zxlist.map(item => {
        this.productlist.forEach(p => {
          if (p.product_title === item.product_title) {
            item.change = p.change
            item.current = p.current
          }
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
*,
:after,
:before {
  box-sizing: border-box;
  margin: 0;
}
.header {
  width: 100%;
}
.maincontent {
  padding: 50px 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  font-size: 0.93333rem;
  background: #fff;
  /deep/.van-tabs__nav--line {
    box-sizing: initial;
    height: 100%;
    padding-bottom: 15px;
  }
  /deep/.van-tab {
    font-size: 1rem;
  }
  /deep/.van-tab--active {
    color: #004ea3;
  }
  // 新闻快讯
  .publish {
    height: 7.2rem;
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
    border-bottom: 0.02667rem solid #e5e5e5;
    display: flex;
    justify-content: center;
    align-content: center;
    .publish-msg {
      height: 80%;
      color: #000;
      font-size: 0.8rem;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      overflow: hidden;
      span {
        line-height: 1.25rem;
      }
    }
  }
  // 自选  // 市场
  .currency-title {
    margin-top: 0.5rem;
    margin-bottom: 0.4rem;
    font-size: 0.8rem;
    color: #b2b2b2;
    span {
      font-size: 0.875rem;
    }
  }
  .currency-Increase {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    line-height: 1.33333rem;
    border-bottom: 0.02667rem solid #e5e5e5;
    .currency-btn {
      border: none;
      border-radius: 0.26667rem;
      width: 5.4rem;
      height: 2.13333rem;
      color: #fff;
      font-size: 1rem;
    }
  }
  // 添加按钮
  .click-add {
    padding-top: 1.5rem;
  }
}
</style>
